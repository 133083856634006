@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
    font-family: 'DM Sans', sans-serif;
}

code {
  font-family: 'DM Sans', sans-serif;
}