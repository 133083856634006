/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
/* 
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  } */
  
  .left-div {
    animation: slideInFromLeft 1s ease-out forwards;
  }
  
  .right-div {
    animation: slideInFromRight 1s ease-out forwards;
  }
  @keyframes radial-bg {
    0% {
      background: radial-gradient(circle, white 0%, blue 0%);
    }
    100% {
      background: radial-gradient(circle, blue 0%, white 100%);
    }
  }
  
  .bg-blue-500-hover {
    position: relative;
    background-color: blue;
    transition: background-color 2s ease;
    overflow: hidden;
  }
  
  .bg-blue-500-hover:hover::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: radial-gradient(circle, white 0%, blue 100%);
    transform: translate(-50%, -50%);
    animation: radial-bg 2s forwards;
    z-index: 0;
  }
  
  .bg-blue-500-hover > * {
    position: relative;
    z-index: 1;
  }
  
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.testimonial-text {
  font-size: 16px;
  line-height: 1.6;
  color: #9EB1B0;
  text-align: left;
}

.testimonial-name {
  font-size: 16px;
  font-weight: 600;
  color: #004643;
}
